  <template>
  <div>
    <div class="content">
      <div class="SearchBar">
        <el-form :inline="true" class="searchBoxForm">
          <el-form-item label="用户分类">
            <el-select
                v-model="pageParams.userType"
                placeholder="请选择"
                size="mini"
                clearable
                filterable
                style="width: 180px;"
                
            >
            <!-- @change="search" -->
              <el-option
                  v-for="(item, index) in userTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="片区">
          <el-select v-model="pageParams.addrId" placeholder="请选择" size="mini" filterable>
            <el-option
                v-for="(item, index) in areaList"
                :key="index"
                :label="item.addrName"
                :value="item.addrId+''"
            ></el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="安检员" class="searchItem">
            <el-select
                clearable
                filterable
                v-model="pageParams.userId"
                placeholder="请选择"
                size="mini"
                style="width: 180px;"
                
            >
              <el-option
                  v-for="(item, index) in peopleList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="启用状态">
            <el-select
                
                clearable
                filterable
                v-model="pageParams.status"
                placeholder="请选择"
                size="mini"
            >
              <el-option
                  v-for="(item, index) in statusList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-select
              v-model="pageParams.keyWord"
              style="width: 100px; margin: 6px 10px"
              placeholder="请选择"
              size="mini"
              filterable
          >
            <el-option
                v-for="item in dataList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input v-model="pageParams.keyWordValue" size="mini" placeholder="请输入关键字" style="width: 150px;margin-right: 10px;margin-top: 6px"></el-input>
          <el-form-item label="" class="searchItems">
             <el-button type="primary" @click="search" size="mini" >查询</el-button>
             <el-button type="warning" @click="restSearch" size="mini" >重置</el-button>
             <el-button type="success" @click="exportFile" size="mini" v-if="$anthButtons.getButtonAuth('daoofangbuyudaochu')">导出</el-button>
          </el-form-item>
        </el-form>
      </div>
        <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;"
            border
            :header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
            height="calc(100vh - 320px)"
            :stripe="true"
          >
          <!-- calc(100vh - 390px) -->
          <el-table-column
            type="index"
            label="序号"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="userName"
            label="用户名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="contact"
            label="联系人"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="userPhone"
            label="电话"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="userTypeName"
            label="用户分类"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="addrName"
            label="片区"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="deptName"
            label="负责部门"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="orderCode" label="安检员" >
            <template slot-scope="{row}">
              <span>{{row.inspectorName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="statusName" label="启用状态" ></el-table-column>
          <el-table-column
            prop="visitWorst"
            label="到访不遇次数"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {userTypeDropDown} from "@/RequestPort/induction";
import {addrDropDown, userTaskList, userTaskListExcel} from "../../RequestPort/staticisc";
import {safetyInspectorDropDown} from "@/RequestPort/staticisc";
import {exportMethod} from "../../common/js/exportExcel";
export default {
  name: 'userList',
  data() {
    return {
      userTypeList:[],
      peopleList:[],
      areaList:[],
      dataList:[
        {label:"用户名称",value:1},{label:"联系人",value:2},{label:"电话",value:3},{label:"地址",value:4}
      ],
      statusList:[
        {id:1,name:'已启用'},{id:2,name:'停用安检'},{id:3,name:'停用不安检'},{id:4,name:"已销户"}
      ],
      pageParams: {
        userType:'',
        userId:'',
        status:'',
        addrId:'',
        keyWord:1,
        keyWordValue:'',
        current: 1,
        size: 100,
      }, //  分页查询参数
      total:null,
      tableData: [{}], //  表格数据
      title:''
    };
  },
  mounted() {
    userTypeDropDown().then(res=>{
      this.userTypeList = res.data
    })
    addrDropDown().then(res=>{
      this.areaList = res.data
    })
    safetyInspectorDropDown().then(res=>{
      this.peopleList = res.data
    })
    this.loadList(this.pageParams);
  },
  methods: {
    exportFile(){
      userTaskListExcel(this.pageParams).then(res=>{
        exportMethod(res,'到访不遇报表—用户维度')
      })
    },
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    restSearch() {
      this.pageParams = {
        userType:'',
        userId:'',
        status:'',
        addrId:'',
        keyWord:1,
        keyWordValue:'',
        current: 1,
        size: 100,
      };
      this.loadList(this.pageParams);
    },
    loadList(obj){
      userTaskList(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
    //跳转
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { id: id, disable: disable } })
    },
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{
  height: 25px;
}
.searchItem {
  margin-bottom: 1%;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  background-color: #ffffff;
}
.content {
  background: #fff;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 210px);
  // height: calc(100vh - 250px);
}
.search{
  width: 190px;
}
.contain{
  height: 595px;
}
</style>

